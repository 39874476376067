import moment from 'moment';
export default function Events() {
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/Princess Nokia/events?app_id=45PRESS_nokia',
		method: 'GET',
		dataType: 'json',
		error: () => {
		},
		success: data => {
			console.log(data);
			const events = $( '#events' );
			let html = '';
			console.log( data );
			if ( data.length ) {
				for ( let event of data ) {
					let region = event.venue.region?event.venue.region:event.venue.country
					const location = event.venue.city + ', ' + region;
					if (event.offers[0]){
						html += '<li><a href="' + event.offers[0].url + '" target="_blank">';
					} else {
						html += '<li><a href="' + event.url + '" target="_blank">';
					}
					html+= moment( event.datetime ).format( 'MMM' ) + " " + moment( event.datetime ).format( 'DD' ) + ' - ' 
					+ event.venue.name + ' ' + location + '</a></li>';
				}
				events.html( html );
			} else {
				events.html( '<li class="event no-event"><span>No upcoming events.</span></li>' );
			}
		},
	} );
}