import 'magnific-popup';
import 'owl.carousel';
import Events from './events';

jQuery( document ).ready( function ( $ ) {
	// Nav
	$( '.mfp-link' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );
	// Videos
	$( '#videos .owl-carousel' ).owlCarousel( {
		items: 1,
		video: true,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="far fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="far fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
	// Music
	$( '#music .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="far fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="far fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
	//tour 
	Events();
	
} );
